<template>
  <div class="container-fluid">
    <div class="register">Register</div>
    <div class="container-body" style="border-top-right-radius: 20px;border-top-left-radius: 20px">
      <legend class="legend-style">Login Information</legend>
      <!-- Username -->
      <div class="form-group">
        <label for="username" class="control-label">
          Username <span class="require">*</span>
        </label>
        <div class="input">
          <input v-model="signupForm.username"
                 type="text"
                 class="form-control"
                 id="username"
                 :placeholder="username"
                 :class="{ error : checkField.usernameinfo }"
                 @blur="checkUserName"
                 @input="!isRegBtnDisable && checkUserName()">
          <div>
            <div class="status-point" style="background-color: #FFB22D"/>
            <div>Username length must be between 6- -20.</div>
          </div>
          <div>
            <div class="status-point" style="background-color: #00CF0F"/>
            <div>Only letters and numbers are allowed.</div>
          </div>
          <div>
            <div class="status-point"/>
            <div>The username cannot be changed once registered.</div>
          </div>
          <div style="color: red">{{ checkField.usernameinfo }}</div>
        </div>
      </div>
      <!-- Password -->
      <div class="form-group">
        <label for="password" class="control-label">Password <span class="require">*</span></label>
        <div class="input">
          <input v-model="signupForm.password"
                 type="password"
                 id="password"
                 :placeholder="password"
                 class="form-control"
                 :class="{ error : checkField.passwordinfo }"
                 @blur="checkPassword"
                 @input="!isRegBtnDisable && checkPassword()">
          <div>
            <div class="status-point" style="background-color: #FFB22D"/>
            <div>Password must be between 6- -20 characters long,</div>
          </div>
          <div>
            <div class="status-point" style="background-color: #00CF0F"/>
            <div>Only letters and numbers are allowed.</div>
          </div>
          <div style="color: red">{{ checkField.passwordinfo }}</div>
        </div>
      </div>
      <!-- Re-enter Password -->
      <div class="form-group">
        <label for="repassword" class="control-label">Re-enter Password<span class="require">*</span>
        </label>
        <div class="input">
          <input v-model="signupForm.repassword"
                 type="password"
                 id="repassword"
                 :placeholder="reEnterPassword"
                 :class="{ error : checkField.repasswordinfo }"
                 class="form-control"
                 @blur="chechRepassword"
                 @input="!isRegBtnDisable && chechRepassword()">
          <div style="color: red">{{ checkField.repasswordinfo }}</div>
        </div>
      </div>
    </div>
    <div class="container-body">
      <legend class="legend-style">Company Information</legend>
      <!-- Company Name -->
      <div class="form-group">
        <label for="companyname" class="control-label">Company Name <span class="require">*</span></label>
        <div class="input">
          <input v-model="signupForm.companyname"
                 type="text"
                 id="companyname"
                 :placeholder="companyName"
                 class="form-control"
                 :class="{ error : checkField.companynameinfo }"
                 @blur="checkCompanyName"
                 @input="!isRegBtnDisable && checkCompanyName()">
          <div style="color: red">{{ checkField.companynameinfo }}</div>
        </div>
      </div>
      <!-- Company Name In English(Option for Chinese Users) -->
      <div class="form-group">
        <label for="companylocalname"
               style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-end; width: 350px">
          <div>Company Name In English</div>
          <div style="font-size: 12px">(Option for Chinese Users)</div>
        </label>
        <div>
          <input v-model="signupForm.companylocalname"
                 type="text"
                 id="companylocalname"
                 :placeholder="companyNameEn"
                 class="form-control">
        </div>
      </div>
      <!-- Website -->
      <div class="form-group">
        <label for="website" class=" control-label">Website</label>
        <div class="">
          <input v-model="signupForm.website"
                 type="text"
                 :placeholder="website"
                 class="form-control"
                 id="website">
        </div>
      </div>
      <!-- Business Activity -->
      <div class="form-group">
        <label style="width: 350px;display: flex;flex-direction: column;justify-content: center;align-items: flex-end">
          <div>Business Activity<span class="require">*</span></div>
          <div style="font-size: 12px">(Multiple Choices Allowed)</div>
        </label>
        <div class="checkbox">
          <div style="width: 100%; height: auto; border: 1px solid #707070; padding: 5px 0; border-radius: 8px"
               :class="{ error : checkField.companytypeinfo }">
            <!-- beef -->
            <div><input v-model="companytype" value="_cattlefarm" type="checkbox" class="checkbox-style"
                        @change="checkCompanyType">Cattle Farm
            </div>
            <div><input v-model="companytype" value="_cattleslaughterhouse" type="checkbox" class="checkbox-style"
                        @change="checkCompanyType">Beef Slaughter/Processing Plant
            </div>
            <div><input v-model="companytype" value="_beefexporter" type="checkbox" class="checkbox-style"
                        @change="checkCompanyType">Beef Exporter to China
            </div>
            <div><input v-model="companytype" value="_beefimporter" type="checkbox" class="checkbox-style"
                        @change="checkCompanyType">Chinese Beef Importer
            </div>
            <div><input v-model="companytype" value="_beefdistributer" type="checkbox" class="checkbox-style"
                        @change="checkCompanyType"> Chinese Beef Wholesaler/Trader
            </div>
            <div><input v-model="companytype" value="_beefproductproducer" type="checkbox" class="checkbox-style"
                        @change="checkCompanyType"> Chinese Beef Products Manufacturer
            </div>
            <div><input v-model="companytype" value="_restaurant" type="checkbox" class="checkbox-style"
                        @change="checkCompanyType"> Chinese Beef Retailer (Restaurant/Supermarket)
            </div>
            <!--other-->
            <div><input v-model="companytype" value="_other" type="checkbox" class="checkbox-style"
                        @change="checkCompanyType">Other
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-body" style="border-bottom-right-radius: 20px;border-bottom-left-radius: 20px">
      <legend class="legend-style">Personal Information</legend>
      <!-- Country -->
      <div class="form-group">
        <label for="country" class="control-label">Country <span class="require">*</span></label>
        <div style="width: 489px;display: flex;justify-content: flex-start">
          <div>
            <select v-model="signupForm.country"
                    class="form-control"
                    :class="{ error : checkField.countryinfo }"
                    id="country"
                    @change="checkCountry">
              <option v-for="country in countrydata" :key="country" class="form-control" :value="country">
                {{ $t(`message["${country}"]`) }}
              </option>
            </select>
          </div>
          <!--Province/State-->
          <div style="display: flex;flex-direction: column">
            <div style="display: flex">
              <label for="province" class="control-label" style="width:150px!important;">Province/State<span
                class="require">*</span></label>
              <select v-model="signupForm.province"
                      :disabled="disableProvince"
                      class="form-control"
                      :class="{ error : checkField.provinceinfo }"
                      id="province"
                      @change="checkProvince">
                <option v-for="province in provincelist" :key="province" :value="province">
                  {{ $t(`message["${province}"]`) }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- Contact Person -->
      <div class="form-group">
        <label for="contactpersonname" class="control-label">Contact Person <span class="require">*</span></label>
        <div style="width: 489px;display: flex;justify-content: flex-start">
          <div class="input">
            <input v-model="signupForm.contactpersonname"
                   style="width: 160px!important;"
                   type="text"
                   id="contactpersonname"
                   class="form-control"
                   :class="{ error : checkField.contactpersonnameinfo }"
                   @blur="checkContact"
                   @input="!isRegBtnDisable && checkContact()">
            <div style="color: red">{{ checkField.contactpersonnameinfo }}</div>
          </div>
          <!--Gender -->
          <div style="display: flex;flex-direction: column">
            <div style="display: flex">
              <label for="contactpersonsex" class="control-label" style="width:150px!important;">Gender
                <span
                  class="require">*</span></label>
              <select v-model="signupForm.contactpersonsex"
                      :disabled="disableProvince" class="form-control"
                      :class="{ error : checkField.contactpersonsexinfo }"
                      id="contactpersonsex"
                      @change="checkSex">
                <option value="_mr">Mr</option>
                <option value="_ms">Ms</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- English Name or Pinyin(Option for Chinese Users) -->
      <div class="form-group">
        <label for="contactpersonenglishname"
               style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-end; width: 350px">
          <div>English Name or Pinyin</div>
          <div style="font-size: 12px">(Option for Chinese Users)</div>
        </label>
        <div class="">
          <input v-model="signupForm.contactpersonenglishname"
                 type="text"
                 class="form-control"
                 id="contactpersonenglishname">
        </div>
      </div>
      <!-- Mobile -->
      <div class="form-group">
        <label for="companyphone" class="control-label">Mobile <span class="require">*</span></label>
        <div class="input">
          <input v-model="signupForm.companyphone"
                 type="text"
                 id="companyphone"
                 class="form-control"
                 :class="{ error : checkField.companyphoneinfo }"
                 @blur="checkPhone"
                 @input="!isRegBtnDisable && checkPhone()">
          <div style="color: red">{{ checkField.companyphoneinfo }}</div>
        </div>
      </div>
      <!-- Tel -->
      <div class="form-group">
        <label for="cellphone" class="control-label">Tel </label>
        <div class="input">
          <input v-model="signupForm.cellphone"
                 type="text"
                 id="cellphone"
                 class="form-control">
        </div>
      </div>
      <!-- Email -->
      <div class="form-group">
        <label for="email" class="control-label">Email <span class="require">*</span></label>
        <div class="input">
          <input v-model="signupForm.companyemail"
                 id="email"
                 type="email" name="companyemail"
                 class="form-control"
                 :class="{ error : checkField.companyemailinfo }"
                 @blur="checkEmail"
                 @input="!isRegBtnDisable && checkEmail()">
          <div style="color: red">{{ checkField.companyemailinfo }}</div>
        </div>
      </div>
      <!--Checkbox-->
      <div class="form-group">
        <label  class="control-label"/>
        <div class="footer">
          <div style="display: flex;justify-content: center;align-items: center;padding: 40px 0 10px 0;color: #C5C5C5;font-size: 10px;">
            <input v-model="isAgree" type="checkbox" class="checkbox-style">
            I agree to the
            <router-link to="/Terms">
          <span class="text-primary">
            terms & conditions
          </span>
            </router-link>
            stated herein.
          </div>
          <div class="register-footer">
            <button :disabled="isRegBtnDisable" type="submit" class="btn btn-primary" :class="{ notAllowed : isRegBtnDisable }" @click.prevent="handleSignUp">
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
    <common-dialog id="signupDialog" message="regsuccessful"/>
  </div>
</template>
<script>

import { countrydata, provincedata } from '@/utils/dictionary'
import CommonDialog from '@/components/CommonDialog'

export default {
  components: { CommonDialog },
  data () {
    return {
      companytype: [],
      countrydata: countrydata,
      signupForm: {
        username: '',
        password: '',
        repassword: '',
        companyname: '',
        companylocalname: '',
        companytype: '',
        country: '',
        province: '',
        contactpersonname: '',
        contactpersonsex: '',
        contactpersonenglishname: '',
        companyphone: '',
        companyfax: '',
        cellphone: '',
        companyemail: '',
        website: ''
      },
      // 报错信息
      checkField: {
        usernameinfo: '',
        passwordinfo: '',
        repasswordinfo: '',
        companynameinfo: '',
        companytypeinfo: '',
        countryinfo: '',
        provinceinfo: '',
        contactpersonnameinfo: '',
        contactpersonsexinfo: '',
        companyphoneinfo: '',
        companyemailinfo: ''
      },
      loginForm: {
        UserName: '',
        Password: ''
      },
      username: 'Enter your user name',
      password: 'Enter your password ',
      reEnterPassword: 'Re-Enter your password ',
      companyName: 'Enter your company name ',
      companyNameEn: 'Enter your company name in English ',
      website: 'Enter your company\'s websit ',
      notEmptySet: new Set([]),
      isRegBtnDisable: true,
      isAgree: false,
      disableProvince: false
    }
  },
  computed: {
    provincelist () {
      const province = this.signupForm.country
      if (province && province !== '_othercountry') {
        return provincedata[province].split('|')
      }
      return []
    }
  },
  watch: {
    'signupForm.country' (val) {
      if (val === '_othercountry') {
        this.disableProvince = true
      } else {
        this.disableProvince = false
      }
    },
    isAgree (newval, oldval) {
      if (newval !== oldval) {
        this.isRegBtnDisable = this.isDisabled()
      }
    }
  },
  mounted () {
  },
  methods: {
    handleSignUp () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.signupForm.companytype = this.companytype.join('|')
      // this.signupForm.producttype = this.producttype.join('|')
      if (this.isAgree && !this.isRegBtnDisable) {
        this.$store.dispatch('Signup', this.signupForm).then(res => {
          this.loginForm.UserName = this.signupForm.username.replace(/\s+/g, '')
          this.loginForm.Password = this.signupForm.password
          this.$store.dispatch('SetLoadingStatus', false)
          // 打开模态框
          this.$bvModal.show('signupDialog')

          this.autoLogin(this.loginForm)
        }).catch((err) => {
          console.log(err.Message)
          this.$store.dispatch('SetLoadingStatus', false)
        })
      }
    },
    checkUserName () {
      // 检查用户名
      if (!this.signupForm.username.replace(/\s+/g, '')) {
        this.notEmptySet.delete('username')
        this.checkField.usernameinfo = 'Please enter your username.'
        this.isRegBtnDisable = this.isDisabled()
      } else if (!/^[a-zA-Z0-9]{6,20}$/.test(this.signupForm.username.replace(/\s+/g, ''))) {
        this.notEmptySet.delete('username')
        this.checkField.usernameinfo = 'Username length must be between 6-20 characters long, only letters and numbers are allowed.'
        this.isRegBtnDisable = this.isDisabled()
      } else {
        // 用户名查重
        this.$store.dispatch('CheckUserName', this.signupForm.username.replace(/\s+/g, '')).then(() => {
          this.notEmptySet.delete('username')
          this.checkField.usernameinfo = 'The username has been taken.'
          this.isRegBtnDisable = this.isDisabled()
        }).catch(() => {
          this.notEmptySet.add('username')
          this.checkField.usernameinfo = ''
          this.isRegBtnDisable = this.isDisabled()
        })
      }
    },
    checkPassword () {
      if (this.signupForm.repassword.replace(/\s+/g, '')) {
        this.chechRepassword()
      }
      if (!this.signupForm.password.replace(/\s+/g, '')) {
        this.notEmptySet.delete('password')
        this.checkField.passwordinfo = 'Please enter your password.'
      } else if (!/^[a-zA-Z0-9]{6,20}$/.test(this.signupForm.password.replace(/\s+/g, ''))) {
        this.notEmptySet.delete('password')
        this.checkField.passwordinfo = 'Password must be between 6-20 characters long, only letters and numbers are allowed.'
      } else {
        this.notEmptySet.add('password')
        this.checkField.passwordinfo = ''
      }
      this.isRegBtnDisable = this.isDisabled()
    },
    chechRepassword () {
      if (!this.signupForm.repassword.replace(/\s+/g, '')) {
        this.notEmptySet.delete('repassword')
        this.checkField.repasswordinfo = 'Please re-enter your password.'
      } else if (this.signupForm.password.replace(/\s+/g, '') !== this.signupForm.repassword.replace(/\s+/g, '')) {
        this.notEmptySet.delete('repassword')
        this.checkField.repasswordinfo = 'Password does not match.'
      } else {
        this.notEmptySet.add('repassword')
        this.checkField.repasswordinfo = ''
      }
      this.isRegBtnDisable = this.isDisabled()
    },
    checkEmail () {
      if (!this.signupForm.companyemail.replace(/\s+/g, '')) {
        this.notEmptySet.delete('companyemail')
        this.checkField.companyemailinfo = 'Please enter your email.'
      } else if (!/(\S)+[@]{1}(\S)+[.]{1}(\w)+/.test(this.signupForm.companyemail.replace(/\s+/g, ''))) {
        this.notEmptySet.delete('companyemail')
        this.checkField.companyemailinfo = 'Please enter a valid email.'
      } else {
        // 邮箱查重
        this.$store.dispatch('CheckRegEmail', this.signupForm.companyemail.replace(/\s+/g, '')).then(() => {
          this.notEmptySet.delete('companyemail')
          this.checkField.companyemailinfo = 'email is already occupied'
          this.isRegBtnDisable = this.isDisabled()
        }).catch(() => {
          this.notEmptySet.add('companyemail')
          this.checkField.companyemailinfo = ''
          this.isRegBtnDisable = this.isDisabled()
        })
      }
    },
    checkCompanyName () {
      this.isEmptyField('companyname', 'companynameinfo', 'Please enter your company name.')
    },
    checkContact () {
      this.isEmptyField('contactpersonname', 'contactpersonnameinfo', 'Please enter your name.')
    },
    // checkPhone () {
    //   this.isEmptyField('companyphone', 'companyphoneinfo', 'Please enter a valid phone number.')
    // },
    checkPhone () {
      if (!this.signupForm.companyphone.replace(/\s+/g, '')) {
        this.notEmptySet.delete('companyphone')
        this.checkField.companyphoneinfo = 'Please enter your mobile.'
      } else if (/^[\u4e00-\u9fa5_a-zA-Z]+$/.test(this.signupForm.companyphone.replace(/\s+/g, ''))) {
        this.notEmptySet.delete('companyphone')
        this.checkField.companyphoneinfo = 'Please enter a valid phone number.'
      } else {
        this.notEmptySet.add('companyphone')
        this.checkField.companyphoneinfo = ''
      }
      this.isRegBtnDisable = this.isDisabled()
    },
    checkCompanyType () {
      this.isEmptyCheck('companytype', 'companytypeinfo', 'Please select your business activity.')
    },
    checkCountry () {
      this.isEmptyField('country', 'countryinfo', 'Please select your country.')
    },
    checkProvince () {
      this.isEmptyField('province', 'provinceinfo', 'Please select your province/state.')
    },
    checkSex () {
      this.isEmptyField('contactpersonsex', 'contactpersonsexinfo', 'Please select your gender.')
    },
    autoLogin (data) {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('Login', data).then(() => {
        this.$store.dispatch('GetUserProfile', data.UserName).then(() => {
          this.$store.dispatch('SetLoadingStatus', false)
        }).catch(() => {
          this.$store.dispatch('SetLoadingStatus', false)
        })
      }).catch(() => {
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    isEmptyField (attr, infoattr, msg) {
      if (attr === 'country' && this.signupForm.country === '_othercountry') {
        this.notEmptySet.add('province')
        this.checkField.provinceinfo = ''
      }
      if (this.signupForm[attr].replace(/\s+/g, '')) {
        this.notEmptySet.add(attr)
        this.checkField[infoattr] = ''
      } else {
        this.notEmptySet.delete(attr)
        this.checkField[infoattr] = msg
      }
      this.isRegBtnDisable = this.isDisabled()
    },
    isEmptyCheck (attr, attrinfo, msg) {
      if (this[attr].length === 0) {
        this[attrinfo] = msg
        this.notEmptySet.delete(attr)
      } else {
        this[attrinfo] = ''
        this.notEmptySet.add(attr)
      }
      this.isRegBtnDisable = this.isDisabled()
    },
    isDisabled () {
      return !(this.isAgree && this.notEmptySet.size === 11)
    }
  }
}
</script>
<style scoped>
.container-fluid {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  flex-direction: column;
  color: #535353;
}

.register {
  font-size: 20px;
  padding: 20px 0;
}

.container-body {
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom: 1px solid #F5F5F5;
}

.legend-style {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}

.form-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.control-label {
  width: 350px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
}

.require {
  margin-left: 5px;
  color: red;
}

input {
  width: 489px;
  height: 35px;
  background-color: #EDEDED;
  border-radius: 8px;
  border: 1px solid #EDEDED;
  padding: 0 15px;
  font-size: 12px;
  margin-left: 20px;
}

.input > div {
  color: #CCCCCC;
  font-size: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}

.input > div > div:first-child {
  width: 6px;
  height: 6px;
  margin-right: 5px;
}

.checkbox {
  width: 489px;
  font-size: 10px;
  margin-left: 20px;
}

.checkbox > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.checkbox > div > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
}

.checkbox-style {
  width: 14px;
  height: 14px;
  border: 1px solid #707070;
  margin-right: 5px;
}

select {
  width: 160px;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #EDEDED;
  background-color: #EDEDED;
  margin-left: 20px;
}

.form-control {
  background-color: #EDEDED;
}

.footer{
  width: 491px;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-items: center;
}

.register-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.register-footer > button {
  width: 347px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #4285F4;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
}

.status-point {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fe5555;
}

.text-primary {
  margin: 0 5px;
}

/*占位符修改*/
input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 10px;
  color: #CCCCCC;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 10px;
  color: #CCCCCC;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-size: 10px;
  color: #CCCCCC;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-size: 10px;
  color: #CCCCCC;
}
</style>
